<template>
  <div class="permission-denied"><content-not-view /></div>
</template>
<script>
import ContentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  components: { ContentNotView },
};
</script>
